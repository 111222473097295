.bubble-chart .recharts-cartesian-grid-horizontal line:nth-last-child(1),
.bubble-chart .recharts-cartesian-grid-horizontal line:nth-last-child(2) {
	display: none;
}

.bubble-chart .recharts-scatter-symbol:nth-last-child(1) path,
.bubble-chart .recharts-scatter-symbol:nth-last-child(2) path,
.bubble-chart .recharts-scatter-symbol:nth-last-child(3) path {
	fill: rgba(186, 205, 236, 0.6);
	stroke: rgb(60, 114, 202);
}

.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(1) text,
.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(2) text,
.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(3) text {
	fill: rgb(60, 114, 202);
	font-weight: 700;
}

.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(4) text,
.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(5) text {
	fill: rgb(51, 51, 51);
}

.bubble-chart .recharts-scatter-symbol:nth-last-child(n + 4) path {
	stroke: rgb(128, 128, 128);
	fill: rgba(221, 221, 221, 0.6);
}

.bubble-chart .recharts-cartesian-axis-tick:nth-last-child(n + 6) text {
	fill: rgb(128, 128, 128);
}

.bubble-chart .recharts-scatter-symbol:nth-last-child(1) #centerDot,
.bubble-chart .recharts-scatter-symbol:nth-last-child(2) #centerDot,
.bubble-chart .recharts-scatter-symbol:nth-last-child(3) #centerDot {
	stroke: rgb(60, 114, 202);
	fill: rgb(60, 114, 202);
}

.bubble-chart .recharts-scatter-symbol:nth-last-child(n + 4) #centerDot {
	fill: rgb(128, 128, 128);
	stroke: rgb(128, 128, 128);
}

.bubble-chart .recharts-scatter-symbol::before {
	content: "";
	display: inline-block;
	width: 15px;
	height: 15px;
	-moz-border-radius: 7.5px;
	-webkit-border-radius: 7.5px;
	border-radius: 7.5px;
	background-color: #69b6d5;
}
