.grid-cols-only-content {
	grid-template-columns: auto;
}

@media (min-width: 1024px) and (max-width: 1279px) {
	.grid-cols-sidebar-content {
		grid-template-columns: 20rem auto;
	}
}

@media (min-width: 1280px) {
	.grid-cols-sidebar-content {
		grid-template-columns: 24rem auto;
	}
}

.sidebar {
	box-shadow: 1px 1px 4px rgba(27, 23, 65, 0.1);
}
